@tailwind base;
@tailwind components;
@tailwind utilities;

:root {

    --default-primary-color: #c2410c;   /* smorange DEFAULT */
    --default-secondary-color: #A6D8B6;  /* smgreen DEFAULT */

    --primary-color: var(--default-primary-color);
    --secondary-color: var(--default-secondary-color);
}

body {
    background-color: #f4f4f5;
}

.react-loading-skeleton {
    line-height: unset !important;
}

.scrollbar::-webkit-scrollbar {
    width: 7px;
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #d4d4d8;
    border-radius: 100px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #15803d;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

ul {
    padding-left: 1.25rem;
    color: #c2410c;
    list-style-type: disc;
}

ul > li {
    color: #7c2d12;
}

ol > li {
    color: #7c2d12;
}

.prose {
    max-width: none !important;
}
